import React, { FC } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from './../materialUI';

interface FormDialogProps {
    buttonComponent?: React.ReactNode;
    title: string;
    description: string;
    content: React.ReactNode;
}

const FormDialog: FC<FormDialogProps> = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                {props.buttonComponent}
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>{ props.title }</DialogTitle>
                <DialogContent>
                    <DialogContentText>{ props.description }</DialogContentText>
                    { props.content }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default FormDialog;
