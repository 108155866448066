import React, { FC, useEffect, useState } from 'react'
import ProjectCard from './ProjectCard'
import ProjectsHeader from './ProjectsHeader'
import { Container, Grid } from './../materialUI'

const ProjectCollection: FC<{}> = () => {

    const [projects, setProjects] = useState<any[]>([]);

    // useEffect(() => {
    //     fetch('https://localhost:7178/api/Project')
    //         .then(response => { return response.json(); })
    //         .then(data => setProjects(data));
    // }, []);

    useEffect(() => {
        fetch('http://jo-hobbies.com/API/api/project')
            .then(response => { return response.json(); })
            .then(data => setProjects(data));
    }, []);

    return (

        <Container maxWidth="xl">
            <ProjectsHeader />

            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                {projects.map(p => 
                    (<Grid item xs={12} sm={6} md={4} xl={3}>
                        <ProjectCard name={p.name} description={p.description} image={p.imageName} />
                    </Grid>)
                )}
            </Grid>

            <div style={{ marginBottom: '40px' }}></div>
        </Container>
    )
}

export default ProjectCollection;