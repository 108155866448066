import React, { FC, useState } from 'react';
import { Card, CardActions, CardContent, CardMedia, Typography, Button } from './../materialUI'

import projectCover from './../../images/covers/projectCover.png'

interface projectCardProps {
    name: string,
    description: string,
    image: any,
}

const ProjectCard: FC<projectCardProps> = ({ name, description, image }) => {
    let imageLocation = 'https://bucket-wwxyut.s3.ap-southeast-2.amazonaws.com/coverimages/' + image;
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                sx={{ height: 200 }}
                image={imageLocation}
                title={name}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">{name}</Typography>
                <Typography variant="body2" color="text.secondary">{description}</Typography>
            </CardContent>
            <CardActions>
                <Button size="small">Visit</Button>
                <Button size="small">Learn More</Button>
            </CardActions>
        </Card>
    );
}

export default ProjectCard;