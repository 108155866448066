import React, { FC } from 'react'
import { Typography, Stack, IconButton, Grid, Container } from './../materialUI'
import { AddCircleIcon, FilterAltIcon } from './../icons'
import FormDialog from './../dialogs/formDialog';
import ProjectCreateDialog from './ProjectCreateDialog';

const ProjectsHeader: FC<{}> = () => {

    return (
        <div style={{ marginBottom: '100px' }}>
            <Grid container justifyContent="space-between">

            <Grid item>
                <Typography variant="h1" component="div" style={{ marginBottom: '20px' }}><strong>Projects</strong></Typography>
                <Typography variant="h6" component="div">
                    Keep track of all the projects you are currently working on or planning to work on later
                </Typography>
            </Grid>

            <Grid item>
                <Stack direction="row" spacing={1}>
                    <FormDialog buttonComponent={<AddCircleIcon />} title="Create New Project" description="" content={ <ProjectCreateDialog /> }/>
                    <IconButton aria-label="filter" color="success" size="large">
                        <FilterAltIcon />
                        <Typography variant="body1">&nbsp; Filter</Typography>
                    </IconButton>
                </Stack>
            </Grid>
            </Grid>
        </div>
    )
}

export default ProjectsHeader;