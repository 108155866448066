import React, { FC } from 'react';
import { TextField, Input, Grid } from './../materialUI';

const ProjectCreateDialog: FC = () => {
    const handleUpload = () => {

    };

    return (
        <>
            <Grid container justifyContent="space-between" columnSpacing={{ sm: 2 }}>
                <Grid item sm={6}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Project Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        style={{ marginBottom: '20px' }}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="author"
                        label="Author Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        style={{ marginBottom: '20px' }}
                    />
                </Grid>
            </Grid>


            <Input
                type="file"
                onChange={handleUpload}
                name="[licenseFile]"
                fullWidth
                style={{ marginBottom: '20px', color: '#BBBBBB' }}
            />

            <Input
                type="date"
                onChange={handleUpload}
                name="[licenseFile]"
                fullWidth
                style={{ marginBottom: '20px', color: '#BBBBBB' }}
            />

            <TextField
                autoFocus
                multiline
                margin="dense"
                id="description"
                label="Project Description"
                type="text"
                fullWidth
                variant="standard"
                style={{ marginBottom: '20px' }}
            />
        </>
    );
}

export default ProjectCreateDialog;