import './App.css';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { DefaultTheme } from './components/theme';

import LoginComponent from './components/login/LoginComponent'
import MainNavigation from './components/navigation/MainNavigation'
import ProjectCollection from './components/project/ProjectCollection'

function App() {
    return (
        <StyledEngineProvider injectFirst>
            <CssBaseline />
            <ThemeProvider theme={DefaultTheme}>
              <div className="App">
                  <MainNavigation />
                  <div style={{ height: '50px' }}></div>
                  <ProjectCollection />
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
