import { createTheme } from '@mui/material';

export const EnviziColors = {
    primary: '#5276ff',
    warning: '#FADC73',
    error: '#ff5556',
    grey: {
        1: '#FCFCFF',
        3: '#ecf0f7',
        5: '#666666',
        7: '#30323F',
    },
};


export const DefaultTheme = createTheme({
    palette: {
        primary: {
            main: '#5276ff',
            light: '#f2f4ff',
        },
        warning: {
            main: EnviziColors.warning,
        },
        text: {
            secondary: '#9e9e9e',
        },
    },
    typography: {
        fontFamily: `'Nunito Sans', sans-serif`,
        h1: {
            fontWeight: 'normal',
            fontSize: '34px',
            lineHeight: '1.2',
        },
        h2: {
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '1.2',
        },

        h3: {
            fontWeight: 'normal',
            fontSize: '20px',
            lineHeight: '1.2',
        },

        h4: {
            fontWeight: 'normal',
            fontSize: '18px',
            lineHeight: '1.2',
        },

        h5: {
            fontWeight: 'normal',
            fontSize: '20px',
            lineHeight: '1.2',
        },

        h6: {
            fontWeight: 'normal',
            fontSize: '18px',
            lineHeight: '1.2',
        },

        body1: {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '20px',
        },

        body2: {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '20px',
        },

        caption: {
            fontSize: 12,
            color: '#9e9e9e',
        },
    },
});